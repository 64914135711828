module.exports = {
    init: function () {
        var $wishlistCarousel = $(".wishlist-carousel");

        if ($wishlistCarousel.length > 0) {
            $wishlistCarousel.owlCarousel({
                loop: false,
                dots: false,
                nav: false,
                margin: 10,
                smartSpeed: 900,
                navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 2,
                    },
                    769: {
                        items: 4,
                    },
                    992: {
                        items: 5,
                    }
                },
            });
            $("div.wishlist-widget-main").removeClass("wishlist-widget-main");
        }
    },
};
